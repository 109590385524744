.unit {
  padding-left: 5px;
  color: var(--text-secondary-color);
}

.value {
}

.wrapper {
  text-align: center;
}
