$size: 20px;

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  .statusCircle {
    width: $size;
    height: $size;
    border-radius: $size;
  }

  .good {
    background-color: var(--green-color);
  }

  .warning {
    background-color: var(--yellow-color);
  }

  .danger {
    background-color: var(--red-color);
  }

  .unknown {
    background-color: var(--gray-color);
  }
}
