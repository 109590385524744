.skeletonLoader {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.skeletonLoader::after {
  content: '';
  bottom: 0;
  left: -50%;
  display: block;
  position: absolute;
  width: 50%;
  height: 1px;
  background: var(--secondary-color);
  animation: load 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
}
