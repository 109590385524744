@import '../../shared/styles/variables';

.wrapper {
  padding: 0 !important;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
}

.statusName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 110px;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
}
