$primary: #122531;
$primary-light: #1d303b;
$secondary: #e4ac00;
$content-background: white;

$table-border-color: #dadee2;
$table-text-secondary-color: #8f9091;

$app-content-padding: 36px;
$expanded-recommendation-content-padding: 12px;

$menu-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
