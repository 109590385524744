.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: var(--surface-color);
  padding: 10px 0;
  box-sizing: border-box;
}

.topPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  margin-bottom: 30px;
}

.userLogo {
  max-width: 75px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
}
