.wrapper {
  width: 100%;
  min-width: 1000px;
  background-color: var(--surface-color);
  box-shadow: 0 0 20px 4px var(--shadow-color);
  z-index: 1;

  .header {
    width: 100%;
    border-bottom: 1px solid var(--border-color);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 16px;
      padding: 11px 0 15px 10px;
    }

    .legends {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-right: 50px;

      .legend {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 25px;

        .legendLabel {
          font-size: 12px;
          margin-left: 10px;
        }

        .actual {
          width: 14px;
          height: 2px;
          background-color: var(--actual-color);
        }

        .optimized {
          width: 14px;
          height: 2px;
          background-color: var(--optimized-color);
        }

        .baseline {
          width: 14px;
          height: 0;
          border-top: 2px dotted var(--baseline-color);
        }
      }
    }
  }

  .valueChart {
    height: 250px;
    margin: 10px 0;
  }

  .captureChart {
    height: 200px;
    margin-top: 10px;
  }
}

.controls {
  position: absolute;
  top: 10px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  width: 120px;

  button {
    background-color: #2196f3;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #1565c0;
    }

    &:active {
      background-color: #0d47a1;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 5px rgba(33, 150, 243, 0.5);
    }
  }
}

.zoomContainer {
  position: relative;
}

.canvas {
  width: 60%;
  height: 1000%;
}
