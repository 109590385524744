.wrapper {
  position: fixed;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  background-color: #051c2c;
  display: flex;
  justify-content: center;
}

.body {
  margin-top: 20%;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
}

.title {
  font-size: 48px;
}

.subtitle {
  font-size: 24px;
}

.email {
  color: #009df9;
}
