$circle-size: 9px;
$width: 28px;
$hor-padding: 3px;

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .label {
    font-size: 14px;
    margin-right: 10px;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: $width;
  height: 14px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--text-color);
  transition: 0.3s;
}

.slider:before {
  position: absolute;
  content: '';
  height: $circle-size;
  width: $circle-size;
  left: $hor-padding;
  bottom: 3px;
  background-color: var(--surface-color);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: var(--secondary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX($width - $circle-size - 2 * $hor-padding);
  -ms-transform: translateX($width - $circle-size - 2 * $hor-padding);
  transform: translateX($width - $circle-size - 2 * $hor-padding);
}

/* Rounded sliders */
.slider.round {
  border-radius: 100px;
}

.slider.round:before {
  border-radius: 50%;
}
