.wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.datePicker {
  width: 160px;
  margin-right: 15px;
  border: 1px solid var(--border-color);
}
