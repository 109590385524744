@import '../../shared/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navBar {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
