.container:not(:last-of-type) {
  margin-right: 15px;
}

.predictionName {
  font-size: 16px;
}

.newRecommendations {
  font-size: 14px;
  color: var(--text-secondary-color);
  margin: 6px 0;
}

.legend {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  margin-bottom: 8px;
}

.legendTextContainer {
  display: flex;
  align-items: center;
}

.legendSymbol {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.legendSeparator {
  color: var(--text-secondary-color);
  margin: 0 6px;
}

.values {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.difference {
  font-size: 40px;
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.differenceIndicator {
  font-size: 14px;
  margin-left: 4px;
}

.charts {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 100px;
  align-items: center;
}

.value {
  margin-right: 8px;
  font-size: 20px;
  text-align: right;
}

.chart {
  width: 100px;
  height: 11px;
}
