@keyframes preloaderAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  position: relative;
  width: 24px;
  height: 24px;
  border: 2px solid var(--secondary-color);
  border-left: 1px solid transparent;
  border-radius: 50%;
  animation: preloaderAnimation 0.9s infinite linear;
}
