.wrapper {
  display: flex;
  flex-direction: row;
  height: 9px;
  width: 100%;

  .section {
    height: 100%;
    display: flex;
  }
}
