@import '../../shared/styles/variables';

.icon g {
  fill: var(--icon-color) !important;
}

.wrapper {
  width: 40px;
  height: 40px;
  margin-top: 20px;
}

.content {
  min-width: 180px;
  padding: 5px 15px;
}

.title {
  font-weight: bold;
  padding: 10px 0;
}

.item {
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
