@import '../../shared/styles/variables';

$input-height: 40px;

.container {
  position: relative;
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $input-height;
  line-height: $input-height;
  padding-left: 12px;
  padding-right: 10px;
  font-size: 14px;

  &:hover {
    background-color: var(--background-color);
  }
}

.inputHighlighted {
  background-color: var(--background-color);
}

.dateToDisplay {
  position: absolute;
  margin-left: 40px;
  color: var(--text-secondary-color);
}

.inputIcon {
  transform: rotate(90deg);
}

.datePicker {
  display: none;
  position: absolute;
  z-index: 100;
  width: 170px;
  box-shadow: $menu-shadow;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  padding: 17px 12px 15px;
}

.datePickerVisible {
  display: block;
}

.datePickerWrapper {
  position: relative;
  font-size: 12px;
  outline: none;
}

.months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.month {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navBar {
  position: relative;
  width: 100%;
}

.navButton {
  top: -2px;
  z-index: 100;

  svg {
    fill: var(--secondary-color) !important;
  }
}

.navButtonPrev {
  position: absolute;
  left: 13px;
  transform: rotate(-90deg);
}

.navButtonNext {
  position: absolute;
  right: 13px;
  transform: rotate(90deg);
}

.navButtonInteractionDisabled {
  display: none;
}

.caption {
  text-align: center;
}

.weekdays {
  display: flex;
  margin-top: 11px;
  margin-bottom: 17px;
}

.weekdaysRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.weekday,
.day {
  flex: 1;
  width: 100%;
  text-align: center;
  line-height: 20px;
}

.weekday abbr[title] {
  text-decoration: none;
}

.week {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.day {
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;

  &[aria-disabled='true'] {
    pointer-events: none;
    background-color: transparent !important;
    border: none !important;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid var(--secondary-color);
    background-color: transparent !important;
    color: inherit !important;
  }
}

.disabled {
  pointer-events: none;
  color: var(--text-secondary-color);
}

.selected {
  background-color: var(--secondary-color) !important;
  color: white !important;
  outline: none;
}

.selectedRange {
  background-color: var(--selection-color);
}

.highlighted {
  border: 1px solid var(--secondary-color) !important;
}
