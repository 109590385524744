.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--surface-color);
}

.detailsShort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}

.toggleChartIcon {
  transition: transform 0.5s;

  svg {
    fill: var(--secondary-color);
  }
}

.openIcon {
  transform: rotate(180deg);
}

.tagValuesChart {
  margin-top: 12px;
}
