.bar {
  margin-bottom: 10px;
}

.downloadIcon {
  width: 40px;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;

  &:hover {
    background-color: var(--hover-color);
  }

  g {
    fill: var(--icon-color) !important;
  }
}

.popupButton {
  cursor: pointer;
  border: 2px solid var(--text-color);
  color: var(--text-color) !important;
  outline: none;
  height: 40px;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  align-self: flex-end;
  margin-top: 15px;

  &:hover {
    background-color: var(--hover-color);
  }
}

.email {
  position: relative;
  width: 100%;
  height: 100%;
}

.fadeInAnimation {
  display: flex;
  opacity: 1;
  animation: fadeIn 1s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.commentTextarea {
  width: calc(100% - 15px);
  height: 60px;
  border: none;
  background-color: var(--background-color);
  resize: vertical;
  font-size: 14px;
}

.popupContentWrapper {
  width: auto;
  min-width: 300px;
  max-width: 450px;
  padding: 15px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.recipientsTitle {
  font-size: 18px;
}

.noRecipients {
  font-style: italic;
}

.recipientsList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.recipient {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 5px 3px 0;
  padding: 2px 5px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  color: var(--text-color);

  &:hover {
    background-color: var(--hover-color);
  }

  svg g {
    fill: var(--icon-color);
  }
}

.addRecipientWrapper {
  display: flex;
  flex-direction: row;
  height: 30px;
}

.recipientInput {
  width: 200px;
  outline: none;
  appearance: none;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  padding: 0 10px;
}

.addRecipientButton {
  width: 25px;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--text-color);
  border-left: none;

  &:hover {
    background-color: var(--background-color);
  }
}
