.periods {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;

  label:not(:last-child) {
    margin-right: 14px;
  }
}
