.wrapper {
  position: relative;
  width: auto;
}

.tooltipWrapper {
  position: absolute;
  pointer-events: none;
}

.tooltip {
  word-break: normal;
  white-space: nowrap;
  pointer-events: none;
  padding: 0 4px;
}

.text {
  font-size: 12px;
  color: white;
}
