.darkTheme {
  // ===== Colors =====
  --primary-color: #4b0082;
  --primary-dark-color: rgba(0, 21, 33);
  --secondary-color: #ffa500;

  --white-color: #ebebeb;
  --dark-color: #081c28;
  --orange-color: #fd7700;
  --green-color: #26c022;
  --blue-color: #164559;
  --red-color: #ff0000;
  --yellow-color: #e4ac00;
  --gray-color: #90999e;

  // ====== Variables =====

  // backgrounds
  --surface-color: var(--primary-color);
  --background-color: #2d004d;
  --background-color-rgb: 108, 50, 80;

  // text
  --text-color: rgba(255, 255, 255, 0.85);
  --text-secondary-color: #74818c;

  // ui elements
  --border-color: #223c47;
  --selection-color: rgba(0, 157, 249, 0.25);
  --hover-color: rgba(var(--background-color-rgb), 0.7);
  --icon-color: var(--text-color);
  --shadow-color: rgba(0, 21, 33);

  // features
  --optimized-color: var(--white-color);
  --baseline-color: rgba(255, 255, 255, 0.4);
  --actual-color: var(--secondary-color);

  --approve-color: var(--green-color);
  --decline-color: var(--red-color);
  --pending-color: var(--gray-color);
  --inreview-color: var(--blue-color);

  color: var(--text-color);
}
