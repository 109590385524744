.container {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 16px;
}

.popupComments {
  width: 100%;
}

.textarea {
  resize: none;
  height: 40px;
  box-sizing: border-box;
  border: none;
  background-color: var(--background-color);
  width: 500px;
  padding: 8px;
}

.predefinedComments {
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  font-size: 12px;
}

.predefinedComment {
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: var(--hover-color);
  }
}
