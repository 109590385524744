.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  margin-left: 5px;
}

.icon {
  opacity: 0.9;

  width: 1.2em;
  height: 1.2em;
  font-size: 0.9em;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  background-color: var(--text-color);
  color: var(--background-color);
}
