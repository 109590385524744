@import '../../shared/styles/variables';

.swagger-ui {
  .wrapper {
    margin: 0;
    max-width: 100%;
    padding: 0 $app-content-padding;
  }

  .model-box table tr td .model .prop > span:not([class]) {
    display: none;
  }
}
