$icon_size: 16px;

.icon {
  width: $icon_size;
  height: $icon_size;
  margin-right: 8px;

  g {
    fill: var(--text-color) !important;
  }
}
