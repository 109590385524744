@import '../../shared/styles/variables';

.appContent {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}

main {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding-left: $app-content-padding;
}
