.wrapper {
  width: 100%;
  box-shadow: 0 0 20px 4px rgba(0, 21, 33, 0.08);
  background-color: var(--surface-color);
}

.chartWrapper {
  margin: 10px 0 5px 10px;
}

.tableOverrides {
  padding-left: 30px;
  padding-top: 10px;
  min-height: 80vh;
}

.tableRow {
  cursor: default;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: row;

  .title {
    white-space: nowrap;
  }

  .square {
    margin-left: 5px;
    width: 9px;
    height: 9px;
  }
}

.verticalBarWrapper {
  margin: 0 10px;
  max-width: 100px;
}

.statusHeader {
  white-space: nowrap;
}

.statusCount {
  width: 100%;
  text-align: center;
}

.recommendationsTitle {
  font-size: 14px;
  white-space: nowrap;
}

.boldText {
  font-weight: bold;
}
