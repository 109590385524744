@import '../../shared/styles/variables';

$open-button-size: 40px;
$menu-top-padding: 4px;
$menu-width: 160px;
$menu-item-height: 40px;

.container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.menu {
  position: absolute;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $menu-width;
  height: 156px;
  box-shadow: $menu-shadow;
  background-color: var(--surface-color);
  padding-top: $menu-top-padding;
}

.downloadButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.downloadButtonTooltip {
  min-width: 120px !important;
  padding: 2px;
}

.datePickerInput {
  height: $menu-item-height;
  line-height: $menu-item-height;
}

.datePickerFrom {
  top: -$menu-top-padding;
  right: $menu-width;
}

.datePickerTo {
  top: -$menu-top-padding - $menu-item-height;
  right: $menu-width;
}
