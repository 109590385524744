.button {
  margin-top: 20px;
  box-sizing: border-box;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  padding: 8px;
  margin-left: calc(100% - 80px);
}

.contained {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
