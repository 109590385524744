$notification-size: 8px;

.notificationCircle {
  width: $notification-size;
  height: $notification-size;
  background-color: var(--secondary-color);
  border-radius: 25px;
  margin: 0 0 0 auto;
}

.notificationWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.notificationTooltip {
  margin-top: 8px !important;
}
