$animation-time: 0.2s;
$box-unchecked-color: var(--gray-color);
$radio-checked-color: var(--secondary-color);
$checkmark-color: var(--secondary-color);

$radio-size: 16px;
$label-margin: 14px;
$checked-size: 8px;

.container {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  align-items: center;
  width: fit-content;
  min-width: $radio-size;
  min-height: $radio-size;

  &:hover input ~ .radio {
    background-color: var(--hover-color);
  }

  &:hover input:not(:checked) ~ .radio {
    opacity: 0.7;
  }
}

.label {
  padding-left: $radio-size + $label-margin;
}

// box
.radio {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  height: $radio-size;
  width: $radio-size;
  border: 2px solid $box-unchecked-color;
  border-radius: 100%;

  // checkmark
  &:after {
    content: '';
    position: absolute;
    display: none;
    width: $checked-size;
    height: $checked-size;
    top: ($radio-size - $checked-size) * 0.5;
    left: ($radio-size - $checked-size) * 0.5;
    border-radius: 100%;
    background: $checkmark-color;
    transition: all 0.2s ease;
  }
}

input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .radio {
    border: 2px solid $radio-checked-color;
  }

  &:checked ~ .radio:after {
    display: block;
  }
}

.disabled {
  color: var(--text-secondary-color) !important;
  cursor: not-allowed !important;
  opacity: 0.6;
}
