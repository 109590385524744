.container {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  background-color: inherit;

  width: 200px;
  height: 50px;
  padding: 14px;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      width: 70%;
      left: 15%;
      bottom: 0;
      height: 1px;
      background-color: var(--text-color);
      opacity: 0.2;
    }
  }

  &:hover {
    background-color: var(--hover-color);
  }
}

.arrow {
  transform: rotate(90deg);
  color: var(--icon-color);
  width: 24px;
  height: 24px;
}
