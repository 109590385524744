.container {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  padding: 16px;
  background-color: var(--surface-color);
  box-shadow: 0 0 20px 4px var(--shadow-color);
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
}

.subtitle {
  font-size: 12px;
  color: var(--text-secondary-color);
}

.aggregation::after {
  content: '|';
  margin: 0 4px;
}

.metrics {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
}

.gridRow {
  display: inline-grid;
  grid-template-columns: 1.5fr 1fr 1fr;
}

.metricNames {
  margin-top: 10px;
}

.metricName {
  font-size: 12px;
  color: var(--text-secondary-color);
  font-weight: normal;
  text-align: center;

  &:first-of-type {
    grid-column-start: 2;
  }
}

.downloadIcon {
  cursor: pointer;

  svg g {
    fill: var(--icon-color);
  }
}
