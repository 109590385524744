.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: flex-start;
}

.userInfo {
  margin-top: 20px;
  margin-left: calc(100% - 235px);
  flex-grow: 1;
  flex-shrink: 1;
  max-width: calc(100% - 110px);
  display: inline-flex;
  overflow: hidden;
}

.buttons {
  width: 100px;
  display: inline-flex;
  margin-left: 10px;
}
