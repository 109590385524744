.container {
  display: flex;
  gap: 16px;
}

.statusButton {
  color: white;
  min-width: 115px;
}

.openPossibleValuesIcon {
  color: var(--text-color);
  transition: transform 0.5s;
}

.iconRotated {
  transform: rotate(180deg);
}

.possibleValuesPopup {
  width: fit-content;
}

.possibleValues {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdownItem,
.dropdownItemClickable,
.dropdownItemTitle {
  padding: 0 12px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.dropdownItemClickable {
  &:hover {
    background-color: var(--hover-color);
  }
}

.dropdownItemTitle {
  white-space: nowrap;
}

.recommendationUnit {
  color: var(--text-secondary-color);
  margin-left: 8px;
}

.declineOption {
  border-top: 1px solid var(--border-color);
}
