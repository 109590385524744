@import '../../shared/styles/variables';

.trackers {
  overflow-x: auto;
}

.trackerContainer {
  display: flex;
  margin: $app-content-padding 0;
}
