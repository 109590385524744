.metricDateRange {
  font-size: 13px;
}

.valueContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.actual {
  font-size: 14px;
  width: 50px;
  text-align: right;
}

.difference {
  font-size: 12px;
  color: var(--green-color);
  border-width: 1px;
  border-style: solid;
  width: 50px;
}

.differencePositive {
  color: var(--green-color);
  border-color: var(--green-color);
}

.differenceNegative {
  color: var(--orange-color);
  border-color: var(--orange-color);
}
