@import '../../shared/themes/dark.module.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  background-color: var(--surface-color);
  min-height: 70px;
  align-items: stretch;
  box-sizing: border-box;
}

.section {
  display: flex;
  flex-direction: row;
}

.title {
  display: flex;
  align-items: center;
  font-size: 20px;
}
