@import '../../shared/styles/variables';

.wrapper {
  position: relative;

  .scroller {
    overflow-x: auto;
  }

  .table {
    .header {
      margin-top: 12px;
      border-bottom: 1px solid var(--border-color);
    }
  }
}
