@import '../../../shared/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  position: relative;
  color: var(--icon-color);
  padding: 0 8px 0 6px;

  min-width: 80px;
  max-width: 100px;
}

.container:hover {
  background-color: var(--hover-color);
}

.container:hover,
.activeLink {
  color: var(--text-color);

  .icon {
    & > div {
      border-color: var(--secondary-color);
    }

    svg {
      color: var(--secondary-color);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.icon {
  display: flex;

  svg {
    color: var(--icon-color);
  }
}

.separator {
  height: 1px;
  background-color: var(--border-color);
  width: 80%;
}

$activeLinkIndicatorHeight: 80%;

.activeLink:after {
  content: '';
  position: absolute;
  right: 0;
  top: (100% - $activeLinkIndicatorHeight) * 0.5;
  height: $activeLinkIndicatorHeight;
  width: 4px;
  background-color: var(--secondary-color);
}

.label {
  text-align: center;
  margin-top: 8px;
  font-size: 12px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}

.defaultIcon {
  width: 22px;
  height: 22px;
  border: 2px solid var(--icon-color);
  border-radius: 100%;
}
