@import '../../shared/styles/variables';

.menu {
  z-index: 1;
  position: absolute;
  background-color: var(--surface-color);
  right: 0;
  font-size: 14px;
  min-width: 160px;
  top: 0;
  box-shadow: $menu-shadow;

  .menuOption {
    cursor: pointer;
    padding: 6px 14px 12px 14px;
  }

  .menuOptionTitle {
    .icon {
      transform: rotate(90deg);
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .menuOptionSelected {
    background-color: var(--background-color);
  }
}

.subMenu {
  z-index: 1;
  position: absolute;
  top: -3px;
  background-color: var(--background-color);
  right: 100%;
  width: 160px;
  padding: 2px 0 8px 12px;
  box-shadow: $menu-shadow;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid var(--border-color);
}
