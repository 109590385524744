.card {
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
  box-shadow: 0 0 20px 4px rgba(0, 21, 33, 0.08);
  padding: 16px;
  box-sizing: border-box;
  background-color: var(--surface-color);
  border-bottom: 1px solid var(--border-color);
}

.loadingPlaceholder {
  height: 300px;
  width: 450px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fadeInAnimation {
  display: flex;
  opacity: 1;
  animation: fadeIn 1s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInAnimation,
.timeWindowDataCard {
  &:not(:last-child) {
    margin-right: 24px;
  }
}
