.wrapper {
  padding: 23px 18px;
  height: 414px;
  display: flex;
  flex-direction: row;
  background-color: var(--surface-color);

  .card {
    background-color: var(--background-color);

    .header {
      height: 51px;
      padding: 0 15px;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 51px);
      box-sizing: border-box;

      padding: 20px 14px;
      font-size: 14px;

      .statusDescription {
        display: flex;
        flex-direction: row;
        align-items: center;

        .statusCircle {
          width: 16px;
          height: 16px;
          border-radius: 20px;
          background-color: var(--yellow-color);
          margin-right: 8px;
        }
      }

      .mean {
        background-color: var(--background-color);
        padding: 14px;

        .meanText {
          margin-bottom: 9px;
        }

        .meanInfo {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          .meanStatus {
            display: flex;
            flex-direction: row;
            align-items: center;

            .meanIcon {
              $icon-size: 5px;

              width: 0;
              height: 0;
              border-left: $icon-size solid transparent;
              border-right: $icon-size solid transparent;

              border-top: $icon-size * 2 solid var(--text-color);

              margin-right: 6px;
            }
          }

          .meanBlock {
            .valueLabel {
              border-bottom: 1px solid var(--border-color);
              padding: 4px 0;
            }

            .value {
              font-size: 16px;
              padding: 4px 0;
              text-align: right;
            }
          }
        }
      }
    }

    .detectionCount {
      display: flex;
      flex-direction: row;
      align-items: center;

      .count {
        margin-right: 5px;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .moreIssues {
      .boldText {
        font-weight: bold;
      }
    }

    .contact {
      .link {
        color: var(--yellow-color);
        text-decoration: underline;
      }
    }

    .datePicker {
      margin-left: 12px;
      background-color: #001521;
      width: 140px;
      padding: 6px 11px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .datepickerIcon {
        transform: rotate(90deg);
      }
    }

    .chartWrapper {
      padding: 4px 26px 10px 5px;
      height: calc(100% - 81px);
      width: calc(100% - 31px);
      position: relative;
      margin-bottom: 10px;
      margin-top: 10px;
      background-color: #1d303b;

      .chartCanvas {
      }
    }
  }

  .rightCard {
    margin-left: 30px;
    width: 90%;
  }
}
