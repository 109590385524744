.section {
  margin: 20px;
  padding: 15px;
  background-color: var(--surface-color);
  box-shadow: 0 0 20px 4px var(--shadow-color);
}

.tableRow {
  padding: 0 5px;
}
