@import '../../shared/styles/variables';

.container {
  position: relative;
  height: 100%;
  display: flex;

  .button {
    height: 100%;
    min-width: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      g {
        fill: var(--icon-color);
      }
    }
  }

  .highlightedButton {
    background-color: var(--hover-color);
  }
}

.childrenWrapper {
  position: absolute;
  z-index: 2;
  right: 0;
  background-color: var(--surface-color);
  box-shadow: $menu-shadow;
  border: solid 1px var(--border-color);
  top: 100%;
}

.childrenWrapperAlignLeft {
  right: auto;
  left: 0;
}

.childrenWrapperAlignTop {
  bottom: 100%;
  top: auto;
}

.tooltip {
  margin-bottom: -5px !important;
}
