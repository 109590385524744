@import 'overrides';
@import 'variables';

@font-face {
  font-family: TitilliumWeb;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/TitilliumWeb-Light.ttf') format('truetype');
}

@font-face {
  font-family: TitilliumWeb;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/TitilliumWeb-Regular.ttf') format('truetype');
}

@font-face {
  font-family: TitilliumWeb;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../assets/fonts/TitilliumWeb-Bold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: TitilliumWeb, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}

textarea::placeholder {
  font-family: TitilliumWeb, sans-serif;
  font-size: 12px;
}

textarea {
  font-family: TitilliumWeb, sans-serif;
  padding: 4px;
  font-size: 12px;
}

body > div > div {
  /* Firefox scrollbar */
  scrollbar-color: var(--border-color) var(--surface-color);
  /* IE scrollbar */
  scrollbar-face-color: #0064a7;
  scrollbar-shadow-color: #8ea5b5;
  scrollbar-highlight-color: #8ea5b5;
}

/* Chrome, Safari, Opera scrollbar */

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar:horizontal {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

::-webkit-scrollbar:vertical {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: var(--border-color);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb:hover {
  opacity: 0.3;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

input,
textarea {
  color: var(--text-color);
}

input::placeholder,
textarea::placeholder {
  color: var(--text-secondary-color);
}
