@import '../../../shared/styles/variables';

.row {
  box-sizing: content-box;

  th:first-child {
    box-sizing: content-box;
    padding-left: 18px;
  }
}

.cell {
  padding: 0 0 11px 0;
  text-align: left;
  color: var(--text-secondary-color);
  box-sizing: content-box;
  user-select: none;
}

.sortedColumn {
  border-bottom: 1px solid var(--secondary-color) !important;
}

.cellWrapper {
  width: 100%;
  height: 100%;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.isSorted {
  g {
    fill: var(--text-color) !important;
  }
}

.icon {
  display: flex;
  transform: rotate(90deg);
  width: 22px;

  g {
    fill: var(--text-secondary-color);
  }
}

.descendingIcon {
  transform: rotate(180deg);
}

//title aligns
.titleAlign_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.titleAlign_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.titleAlign_left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.separator {
  height: 12px;
  width: 100%;
  border-top: 1px solid var(--border-color);
}
