.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: var(--secondary-surface-color);
  }
}

.icon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5px;

  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;

  g {
    fill: var(--icon-color);
  }
}

.labelWrapper {
  display: flex;
  height: 100%;
}

.label {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;

  font-size: 12px;
}

.primaryHighlight {
  background-color: var(--secondary-surface-color);
}

.secondaryHighlight {
  background-color: #2b6183;
}
