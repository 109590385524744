.wrapper {
  width: 400px;
  z-index: 1;
  position: relative;

  .resetFilters {
    color: var(--yellow-color);
    text-decoration: underline;
    white-space: nowrap;
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
  }

  .filterGroup {
    padding: 16px 0;

    &:first-child {
      padding-top: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-color);
    }

    .filter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 11px 11px 14px 11px;
      margin: 12px 20px 12px 0;
      background-color: var(--surface-color);
      cursor: pointer;
      box-shadow: 0 0 20px 4px rgba(0, 21, 33, 0.08);

      &:first-child {
        margin-top: 0;
      }

      .filterName {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 10px;
      }

      .rightPart {
        display: flex;
        flex-direction: row;
        align-items: center;

        .statusBarChart {
          width: 80px;
          margin-right: 15px;
        }

        .icon {
          flex-direction: row;
          border-left: 1px solid var(--border-color);
          padding-left: 10px;

          svg {
            transform: rotate(90deg);
          }
        }
      }
    }

    .activeFilter {
      padding-right: 62px;
      margin-right: 0;
      box-shadow: -15px 0 20px 0px rgba(0, 21, 33, 0.08);
    }
  }
}

.selectFilterIcon {
  flex-direction: row;
  border-left: 1px solid var(--border-color);
  padding-left: 10px;
  display: flex;
  align-items: center;
}
