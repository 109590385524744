@import '../../shared/styles/variables';

.wrapper {
  width: 100%;
}

.section {
  padding: 15px;
  margin: $app-content-padding 0;
  background-color: var(--surface-color);
  box-shadow: 0 0 20px 4px var(--shadow-color);
}

.tablesStats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $app-content-padding;
}

.halfSection {
  padding: 15px;
  background-color: var(--surface-color);
  box-shadow: 0 0 20px 4px var(--shadow-color);
  width: calc(50% - 50px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  align-items: center;

  &:hover {
    background-color: var(--hover-color);
  }
}

.link {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

.number {
  color: var(--secondary-color);
}

.tableDesc {
  font-style: italic;
  font-size: 14px;
}

.subTitle {
  font-size: 20px;
}

.numberOfEntities {
  color: var(--secondary-color);
}

.tableName {
  cursor: pointer;
  text-decoration: underline;
}

.dbStats {
  display: flex;
  justify-content: space-around;
}

.separator {
  height: 1px;
  width: calc(100% - 40px);
  background-color: var(--border-color);
  margin: 30px 0;
}
