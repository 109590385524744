@import '../../shared/styles/variables';
@import '../../shared/styles/skeletonLoader';

.container {
  display: flex;
  flex-direction: column;
  padding: $app-content-padding 0;
  overflow-x: auto;
}

.loaderPlaceholder {
  background-color: var(--surface-color);
  width: 300px !important;
  height: 130px !important;
}

.cardContainer {
  border-top: 5px solid;
  min-width: 350px;

  &:not(:last-child) {
    margin-right: 24px;
  }
}
