.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 20px;
}

.summary {
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: center;
  min-width: 35px;
  padding: 0 3px;
}

.arrow {
  fill: var(--text-color);

  &:hover {
    fill: var(--secondary-color) !important;
  }
}

.pageLabel {
  &:hover {
    color: var(--secondary-color);
  }
}

.selected {
  border-bottom: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}

.rotate90 {
  display: block;
  transform: rotate(90deg);
}

.rotate270 {
  display: block;
  transform: rotate(270deg);
}
