@import 'variables';

$checkbox-color: #9e9e9e;

/* Kedro-ui theme overrides */

/* Icon */

.kui-icon {
  width: 20px !important;
  height: 20px !important;
  display: block !important;
}

/* Checkbox */
/* change checkbox size */
/* change checkbox colors */
/* add border color when checkbox is set */
/* remove useless margin */
/* scale a little to remove white gap */

.kui-switch-checkbox__box {
  border-color: $table-border-color !important;
  width: 12px !important;
  height: 12px !important;
}

.kui-switch-checkbox__inner {
  width: 16px !important;
  height: 16px !important;
}

.kui-switch__input:checked + .kui-switch-checkbox__label {
  border-color: $checkbox-color !important;
}

.kui-switch-checkbox__innerfill {
  transform: scale(1.1) translate(-1px, -1px);
  fill: $checkbox-color !important;
}

.kui-switch {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
}

.kui-switch-checkbox__box {
  margin-right: 0 !important;
}

// kedro-ui Tabs overrides
.kui-tabs__list {
  padding: 0;
  margin: 0;
  border-bottom: none !important;

  .kui-tabs__tab {
    height: 100%;

    &:hover:after,
    &:after {
      top: 12px;
    }

    &:after {
      background-color: var(--secondary-color) !important;
    }

    .kui-tabs__button {
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }
}

.swagger-ui {
  filter: invert(88%) hue-rotate(180deg);
}

.swagger-ui .highlight-code {
  filter: invert(100%) hue-rotate(180deg);
}
