@import '../../../shared/styles/variables';
@import '../../../shared/styles/skeletonLoader';

.container {
  border-bottom: 1px solid var(--border-color);
}

.content {
  box-sizing: border-box;
  background-color: transparent;
  vertical-align: middle;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: var(--surface-color);
  }
}

.cell {
  align-self: center;
  padding: 22px 5px 22px 5px;
  overflow-wrap: break-word;
}

.rowSelected,
.rowExpanded {
  background-color: var(--surface-color);
}

.inactive {
  position: absolute;
  background-color: rgba(var(--background-color-rgb), 0.4);
  width: 100%;
  height: 100%;
  pointer-events: none;
}
