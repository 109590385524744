@import '../../shared/styles/variables';

$datePickerHeight: 24px;
$legendMargin: 12px;

.container {
  display: flex;
  flex-direction: column;
  height: 400px;
  position: relative;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  min-height: 44px;
  padding: 0 $expanded-recommendation-content-padding;
  box-sizing: border-box;
}

.datePickers {
  position: absolute;
  right: 0;
  display: flex;
  padding-top: $expanded-recommendation-content-padding;
  padding-right: $expanded-recommendation-content-padding;

  & > div:not(:last-of-type) {
    margin-right: 12px;
  }
}

.datePicker {
  right: 0;
}

.datePickerInput {
  width: 140px;
  border: 1px solid var(--border-color);
  height: $datePickerHeight !important;
  padding-right: 0 !important;
}

.legend {
  position: absolute;
  top: $expanded-recommendation-content-padding + $datePickerHeight +
    $legendMargin;
  right: $expanded-recommendation-content-padding;
  display: flex;
}

.legendItem {
  display: flex;
  align-items: center;
  font-size: 12px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.legendBox {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-color: var(--text-color);
}

.recommendedLegend {
  background-color: var(--secondary-color);
}

.rangeLegend{
  background-color: green;
}
.chart {
  position: relative;
  width: 100%;
  height: 100%;
  padding: $expanded-recommendation-content-padding;
  box-sizing: border-box;
  margin: $legendMargin auto auto;
}
