@import '../../shared/themes/dark.module';

.statusChangeInHeader {
  display: flex;
  align-items: center;
}

.tableStyles {
  overflow-x: auto;
}

.changesIcon {
  width: 8px;
  height: 8px;
  border: 1px solid var(--table-changes);
  border-radius: 25px;
  margin-right: 5px;
}

.lastComment {
  width: 100%;
  white-space: pre-line;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
}
.count {
  color: var(--secondary-color);
  margin-right: 5px;
}

.targetColor {
  position: absolute;
  top: 12.5%;
  height: 75%;
  width: 5px;
}
