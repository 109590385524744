.wrapper {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.filterCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 0;
}
